/*
These methods can be used to send request headers and/or data/payload to a RESTful-API.
To be used with "fetch" method.  
*/
const BEARER_STRING = "Bearer ";
const CONTENT_TYPE_JSON_STRING = "application/json";

export const requestHeaderGET = (ACCESS_TOKEN) => {
	return (
		{
			method: "GET",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN
			}
		}
	)
};

export const requestHeaderPOST = (ACCESS_TOKEN, data) => {
	return (
		{
			method: "POST",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN,
				"Content-Type": CONTENT_TYPE_JSON_STRING
			},
			body: data
		}
	)
};

export const requestHeaderPUT = (ACCESS_TOKEN, data) => {
	return (
		{
			method: "PUT",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN,
				"Content-Type": CONTENT_TYPE_JSON_STRING
			},
			body: data
		}
	)
};

export const requestHeaderDELETE = (ACCESS_TOKEN, data) => {
	return (
		{
			method: "DELETE",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN,
				"Content-Type": CONTENT_TYPE_JSON_STRING
			},
			body: data
		}
	)
};

export const requestHeaderFilePOST = (ACCESS_TOKEN, data) => {
	return (
		{
			method: "POST",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN
			},
			body: data
		}
	)
};

export const requestHeaderFilePUT = (ACCESS_TOKEN, data) => {
	return (
		{
			method: "PUT",
			headers: {
				"Authorization": BEARER_STRING + ACCESS_TOKEN
			},
			body: data
		}
	)
};