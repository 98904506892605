export const TRANSLATIONS_EN = {
	language: "Language",

	assessment: {
		score: {
			percentile: {
				name: "National Percentile",
				description: "the score compared to individuals of the same age. It ranges between 0 and 100 with 50% being average."
			}
		},
		constructs: {
			agreeableness: {
				name: "Agreeableness",
				description: "The extent to which a person is cooperative, empathetic, and compassionate towards others",
				scaleLeft: {
					name: "Contrary",
					description: "Characterized by a strong desire to win or be the best; often involves comparing oneself against others, with an emphasis on achieving superiority or success at the expense of others."
				},
				scaleRight: {
					name: "Cooperative",
					description: "Showing sympathy and concern for the sufferings or misfortunes of others; often involves a desire to help or alleviate the pain and hardships of others."
				}
			},
			conscientiousness: {
				name: "Conscientiousness",
				description: "Characterized by diligence, carefulness, thoroughness, self-discipline, and the desire to do tasks well.",
				scaleLeft: {
					name: "Careless",
					description: " Exhibiting a lack of attention to detail or thoughtfulness in tasks or responsibilities, often leading to mistakes or oversight. Careless behavior is typically marked by a disregard for accuracy and a failure to take the necessary care in activities."
				},
				scaleRight: {
					name: "Diligent",
					description: "Demonstrating careful and persistent work or effort. Diligent individuals are thorough, meticulous, and consistently attentive to the task at hand, often leading to high-quality outcomes."
				}
			},
			"emotion-regulation": {
				name: "Emotion Regulation",
				description: "An individual's ability to effectively manage and respond to their emotional experiences.",
				scaleLeft: {
					name: "Reactive",
					description: "Characterized by immediate, often emotional responses to situations or stimuli, without significant deliberation or control over one's emotions."
				},
				scaleRight: {
					name: "Resilient",
					description: "The ability to adapt well and recover quickly from stress, adversity, or trauma, often involving effective coping mechanisms and emotional flexibility."
				}
			},
			engagement: {
				name: "Engagement",
				description: "the level of a person's psychological investment and active involvement in activities, tasks, or interactions.",
				scaleLeft: {
					name: "Apathetic",
					description: "Showing or feeling no interest, enthusiasm, or concern; a lack of engagement or emotional investment in activities or interactions."
				},
				scaleRight: {
					name: "Enthusiastic",
					description: "Exhibiting intense and eager enjoyment, interest, or approval; being highly engaged and energetically involved in activities or tasks."
				}
			},
			extraversion: {
				name: "Extraversion / Introversion",
				description: "The extent to which a person is outgoing and energized by social interactions (Extraversion) versus reserved and energized by solitary activities (Introversion).",
				scaleLeft: {
					name: "Introverted",
					description: "Characterized by a preference for solitary activities and a tendency to be reserved and reflective; introverts often recharge by spending time alone and may feel drained by extensive social interaction."
				},
				scaleRight: {
					name: "Extroverted",
					description: "Exhibiting outgoing, energetic behavior, and enjoying being around and interacting with others; extraverts are often energized by social gatherings and external stimuli."
				}
			},
			grit: {
				name: "Grit",
				description: "The perseverance and passion for long-term goals, encompassing an individual's persistence of effort and consistency of interest over time.",
				scaleLeft: {
					name: "Inconsistent",
					description: "Characterized by a lack of steadiness and regularity in actions or effort, often resulting in a failure to follow through on commitments or to maintain focus on long-term goals."
				},
				scaleRight: {
					name: "Persistent",
					description: "Exhibiting a firmness of purpose and a steadfast adherence to a course of action, often indicative of a strong commitment to achieving long-term objectives despite challenges and setbacks."
				}
			},
			growth: {
				name: "Growth Mindset",
				description: "The belief that abilities and intelligence can be developed through dedication, hard work, and perseverance, emphasizing learning and resilience in the face of challenges.",
				scaleLeft: {
					name: "Fixed",
					description: "The belief that abilities, intelligence, and talents are fixed traits that cannot be significantly developed or changed. Individuals with a fixed mindset may avoid challenges and feel threatened by the success of others."
				},
				scaleRight: {
					name: "Adaptive",
					description: "Characterized by flexibility, a willingness to learn and adjust to new circumstances or information, and the ability to thrive in changing environments. An adaptive mindset aligns with the principles of a growth mindset, focusing on development and improvement."
				}
			},
			"negative-feelings": {
				name: "Negative Feelings",
				description: "The extent to which a person experiences and is affected by emotions such as sadness, anger, anxiety, and other unpleasant emotions.",
				scaleLeft: {
					name: "Unaffected",
					description: "Characterized by a lack of emotional disturbance or reactivity to negative stimuli; individuals who are unaffected maintain emotional stability and are not easily swayed by adverse feelings."
				},
				scaleRight: {
					name: "Sensitive",
					description: "Demonstrating a heightened responsiveness to negative emotions; sensitive individuals may experience intense reactions to unpleasant feelings and may be more deeply impacted by negative experiences."
				}
			},
			neuroticism: {
				name: "Neuroticism",
				description: "Signifies a higher tendency towards experiencing negative emotions like anxiety, sadness, and mood swings.",
				scaleLeft: {
					name: "Stable",
					description: "Indicative of emotional steadiness and resilience; individuals who are stable typically experience fewer mood swings and are less likely to feel stressed or upset in challenging situations."
				},
				scaleRight: {
					name: "Anxious",
					description: "Characterized by frequent feelings of worry, nervousness, or unease, often about imminent events or something with an uncertain outcome. People with high levels of neuroticism may be more prone to experiencing such emotions."
				}
			},
			openness: {
				name: "Openness",
				description: "Reflects a person's willingness to consider new ideas, experiences, and values, and their preference for creativity and variety.",
				scaleLeft: {
					name: "Conventional",
					description: "Characterized by a preference for familiarity, tradition, and the established way of doing things. Individuals with a conventional approach often prefer routine and may be resistant to change."
				},
				scaleRight: {
					name: "Creative",
					description: "Demonstrating creativity, originality, and a readiness to explore new and unconventional ideas or methods. Inventive individuals are often open to diverse experiences and ways of thinking."
				}
			},
			"positive-feelings": {
				name: "Positive Feelings",
				description: "The frequency and intensity with which a person experiences pleasant emotions, such as happiness, joy, contentment, and love.",
				scaleLeft: {
					name: "Reserved",
					description: "Characterized by a more subdued or controlled expression of emotions, often implying a lesser frequency or intensity of experiencing overtly positive feelings."
				},
				scaleRight: {
					name: "Upbeat",
					description: " Exhibiting high levels of enthusiasm, energy, and joy; individuals who are exuberant often feel and express positive emotions intensely and vividly."
				}
			},
			reflection: {
				name: "Reflection",
				description: "The ability or tendency to question and analyze one's own thought processes and responses, especially when faced with problems or decisions.",
				scaleLeft: {
					name: "Reactive",
					description: "Characterized by a tendency to act quickly and without sufficient thought or deliberation, often leading to hasty and rash decisions without considering potential consequences."
				},
				scaleRight: {
					name: "Reflective",
					description: "Demonstrating thoughtful consideration and examination of one's own beliefs, decisions, and responses. Reflective individuals tend to analyze situations thoroughly and are more likely to engage in introspection."
				}
			},
			"self-control": {
				name: "Self Control",
				description: "The ability to regulate one's emotions, thoughts, and behavior in the face of temptations and impulses.",
				scaleLeft: {
					name: "Impulsive",
					description: "Characterized by acting on desires and urges rather quickly without adequate forethought or consideration of consequences. Impulsive individuals often find it challenging to resist immediate temptations."
				},
				scaleRight: {
					name: "Disciplined",
					description: "Exhibiting a high level of self-restraint and the ability to resist short-term temptations in order to meet long-term goals. Disciplined individuals are typically consistent in their habits and decision-making processes."
				}
			},
			stress: {
				name: "Stress Management",
				description: "Stress management in the involves using techniques and strategies to reduce and control stress, enhancing well-being and performance.",
				scaleLeft: {
					name: "Resilient",
					description: "Exhibiting the ability to quickly recover from stress, demonstrating adaptability and mental toughness in the face of difficulties. Resilient individuals can effectively manage stress and bounce back from challenges."
				},
				scaleRight: {
					name: "Easily overwhelmed",
					description: "Feeling inundated and unable to cope effectively with stress, often resulting in significant disruption to emotional and physical wellbeing. Overwhelmed individuals might experience heightened anxiety and have difficulty managing stressors."
				}
			},
			"time-mgmt": {
				name: "Time Management",
				description: "The skill of organizing and planning how to divide one's time between specific activities to increase efficiency and productivity.",
				scaleLeft: {
					name: "Disorganized",
					description: "Demonstrating a lack of structure or planning in managing time, often leading to inefficiency, missed deadlines, and an inability to prioritize tasks effectively."
				},
				scaleRight: {
					name: "Efficient",
					description: "Characterized by a high level of organization and the ability to use time productively and judiciously, often resulting in the successful completion of tasks in a timely manner."
				}
			}
		}
	},
	reports: {
		education: {
			family: {
				name: "Overview Report",
				greeting: "Dear Family Member(s)",
				overviewEf: "Executive Function (EF) is a set of brain-based skills that helps people to control their own behavior \
					and direct it toward goals instead of doing what is automatic or easiest. EF skills are important for \
					learning in classroom settings, getting along with other children, and making good decisions. The three \
					skills of EF include working memory, cognitive flexibility, and impulse control. These skill can be \
					measured, and reinforced through reflection and practice.",
				overviewGame: "You participated in a game-like assessment of their Executive Function skills called EFgo™. This game \
				  asks children to match and sort pictures based on features such as size, color, and shape. At each level, \
					you were asked to follow one rule, and then switch and follow a different rule. To play this game, children \
					need to pay attention, remember the rules, and think flexibly.",
				overviewResults: "Following are the results from the most recent assessment and how the scores align with age expectation compared to our national norms of over 53,000 children.",
				activities: {
					overview: "The following are some ideas for activities to support the growth of EF skills:",
					option1: "Practice movement games that require children to calm their bodies, such as walking in a perfectly straight line, doing simple yoga poses, or “freezing” in the middle of an action.",
					option2: "Try switching rules to games children are comfortable playing (e.g., playing opposite Simon Says \
						where you DON’T do the actions that Simon Says, or doing the BINGO song where you start with no \
						letters and add them in, instead of taking them out).",
					option3: "Have your child share and reflect on what they did in school during the day. Verbal processing and reflection are key to EF growth.",
				},
				learnMore: "To learn more, check out Reflective Performance's resources"
			}
		}
	},

	forms: {
		common: {
			selectMonth: "Select Month...",
			enter: "Enter",
			months: {
				january: "January",
				february: "February",
				march: "March",
				april: "April",
				may: "May",
				june: "June",
				july: "July",
				august: "August",
				september: "September",
				october: "October",
				november: "November",
				december: "December"
			},
		},
		participant: {
			studentId: "Participant ID",
			email: "Email",
			name: "Name",
			firstName: "First Name",
			lastName: "Last Name",
			selectGender: "Select Gender...",
			gender: "Gender",
			genderOptions: {
				female: "Female",
				male: "Male",
				other: "Gender diverse",
				private: "Prefer not to say"
			},
			birthMonthYear: "Birth Month / Year",
			birthYear: "Birth Year",
			selectBirthMonth: "Select Birth Month...",
			raceAndEthnicity: "Race / Ethnicity",
			add: {
				title: "Need to be added?",
				message: "Add individual to be assessed",
				button: "Continue"
			},
			edit: {
				title: "Update information of individual to be assessed",
				button: "Continue"
			},
		},
	}
}