import * as actions from "../actionTypes/userActionTypes";

let initialState = {
  userProfile: null,
	currentOrgId: null,
	currentOrgPlan: null,
	currentUserRole: null,
	currentGroupId: null,
	accessToken: null,
	idToken: null,
	loaded: false,
	isDataPrivacyMode: false
};

export default function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
				currentOrgId: action.payload.currentOrgId,
				currentOrgPlan: action.payload.currentOrgPlan,
				currentUserRole: action.payload.currentUserRole,
				currentGroupId: action.payload.defaultGroupId,
				accessToken: action.payload.accessToken,
				idToken: action.payload.idToken
			};
		case actions.SET_DATA_PRIVACY_MODE:
			return {
				...state,
				isDataPrivacyMode: action.payload,
			};
		case actions.RESET_STATE:
			return initialState;
    default:
      return state;
  }
}