import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import helpReducer from "./helpReducer";
import appReducer from "./appReducer";
import orgReducer from "./orgReducer";
import userReducer from "./userReducer";
import coreuiReducer from "./coreuiReducer";
import sessionStorage from "redux-persist/es/storage/session";

const persistRootConfig = {
  key: "root",
  storage: storage,
  whitelist: ['orgReducer']
};

const persistUserConfig = {
  key: "user",
  storage: sessionStorage,
};

const persistedHelpReducer = persistReducer(persistRootConfig, helpReducer);
const persistedAppReducer = persistReducer(persistRootConfig, appReducer);
const persistedOrgReducer = persistReducer(persistRootConfig, orgReducer);
const persistedCoreUIReducer = persistReducer(persistRootConfig, coreuiReducer);
const persistedUserReducer = persistReducer(persistUserConfig, userReducer);

const rootReducer = combineReducers({
  help: persistedHelpReducer,
  application: persistedAppReducer,
  organization: persistedOrgReducer,
  user: persistedUserReducer,
  coreui: persistedCoreUIReducer,
});

export default rootReducer;
