import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_CONFIG } from "./AuthConfig";

export const Auth0ProviderWithRedirect = ({ children }) => {
  const navigate = useNavigate();

  const domain = AUTH_CONFIG.domain;
  const clientId = AUTH_CONFIG.clientId;
  const redirectUri = AUTH_CONFIG.callbackUrl;
  const audience = AUTH_CONFIG.audience;
	const responseType = AUTH_CONFIG.responseType;
	const scope = AUTH_CONFIG.scope;
	const cacheLocation = "localstorage";

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
			cacheLocation={cacheLocation}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
				responseType: responseType,
				scope: scope
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};