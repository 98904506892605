import moment from "moment";
import logo from '../assets/img/brand/logo.png';
import * as Config from './config/Config';
import { differenceInMonths, formatNoOfMonths, isExpired } from "./DateTimeUtil";
import { PLAN_PERFORM_EF, PLAN_REFLECT_EF } from "../managers/org_and_group_manager"; 
import  validator  from 'validator';

const __BRACKET_PAIR__ = "() ";
const __DOUBLE_COLON__ = " : ";
const __CALLED__ = "called";
const __HYPHEN__ = " - ";
const randomNumberGenerator = (upperBound) => {
    // { x : x E Z , x E [0 , upperBound)}
    //returns a pseudo-random INT value ,b/w 0 (inclisuve) and max (exclusive)
    return Math.floor(Math.random() * Math.floor(upperBound));
}

//The main logger for this web-app.
const Logger = (fileName, methodName, message = undefined, variableName = undefined, variableValue = undefined) => {
    const loggerEnabled = localStorage.getItem("log");
    if (loggerEnabled) {
        let logData = fileName + __HYPHEN__ + methodName + __BRACKET_PAIR__ + __CALLED__ + __DOUBLE_COLON__;

        (message) && (logData += (message + __DOUBLE_COLON__));

        if (variableName) {
            logData += (variableName + __DOUBLE_COLON__);
            console.log(logData, variableValue);
        }
        else {
            console.log(logData);
        }
    }
}

const reloadPage = () => {
    window.location.reload();
};

const goBack = () => {
    window.history.back();
}

const goHome = () => {
    window.open("/","_self");
}

const gotoPage = (url) => {window.location = url};
const getStatusBadge = (status) => {
  return status === 'ACTIVE' ? 'success' :
    status === 'INACTIVE' ? 'secondary' :
      'secondary'
}

const formatPercentageFromDecimal = (percentDecimal) => {
  let pctFormatted = "-";
  if (percentDecimal !== undefined && percentDecimal !== null && percentDecimal !== '') {
		pctFormatted = Math.round((percentDecimal * 100)) + "%"
  }
  return pctFormatted;
}

const formatPercentileRank = (percentile) => {
  let formattedRank = "-";
  if (percentile !== undefined && percentile !== null && percentile !== '') {
    let rank = Math.round(percentile);
    let lastDigit = rank % 10;
    formattedRank = rank;
    if (rank === 11 || rank === 12 || rank === 13) {
      formattedRank += "th";
    } else if ( lastDigit === 1) {
      formattedRank += "st";
    } else if ( lastDigit === 2) {
      formattedRank += "nd";
    } else if ( lastDigit === 3) {
      formattedRank += "rd";
    } else {
      formattedRank += "th";
    }
  }
  return formattedRank;
}

const formatStandardScore = (standardScore) => {
  let formattedScore = "-";
  if (standardScore === undefined || standardScore === null || standardScore === '') {
    formattedScore = "-";
  } else {
    formattedScore = standardScore.toLocaleString(navigator.language, { maximumFractionDigits: 0 })
    if (standardScore === 0) {
      formattedScore = "-";
    }  
  }
  return formattedScore;
}

export const formatPercentileChange = (percentileChange) => {
  let formattedText = "";
	let textColorClass="";
	let iconClass="";
  if (percentileChange !== undefined && percentileChange !== null && percentileChange !== '' && percentileChange != 0) {
		formattedText = Math.abs(Math.round(percentileChange))
		if (percentileChange > 0) {
			iconClass="fa fa-caret-up me-1"
			textColorClass="text-positive"
		} else if (percentileChange < 0) {
			iconClass="fa fa-caret-down me-1"
			textColorClass="text-negative"
		}
	}
  return <span className={textColorClass}><i className={iconClass}></i>{formattedText}</span>
}

export function formatNumberIntl(value, precision) {
	const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 0,
			maximumFractionDigits: precision,
	});
	return formatter.format(value);
}
const getScoreCategory = (standardScore) => {
  let category = 0;
  if (standardScore >= Config.SCORE_APPROACHING_MIN && standardScore < Config.SCORE_MEETS_LOW_MIN) {
    category = 1;
  } else if (standardScore >= Config.SCORE_MEETS_LOW_MIN && standardScore < Config.SCORE_MEETS_MID_MIN) {
    category = 2;
  } else if (standardScore >= Config.SCORE_MEETS_MID_MIN && standardScore < Config.SCORE_MEETS_HIGH_MIN) {
    category = 3;
  } else if (standardScore >= Config.SCORE_MEETS_HIGH_MIN && standardScore < Config.SCORE_EXCEEDS_MIN) {
    category = 4;
  } else if (standardScore >= Config.SCORE_EXCEEDS_MIN && standardScore <= Config.SCORE_EXCEEDS_MAX) {
    category = 5;
  }
  return category;
}

export const getPercentileCategoryLabel = (percentile) => {
	let category = Config.SCORE_UNTESTED_LABEL
  if (percentile >= Config.PERCENTILE_APPROACHING_MIN && percentile < Config.PERCENTILE_MEETS_LOW_MIN) {
    category = Config.SCORE_APPROACHING_LABEL
  } else if (percentile >= Config.PERCENTILE_MEETS_LOW_MIN && percentile < Config.PERCENTILE_MEETS_MID_MIN) {
    category = Config.SCORE_MEETS_LOW_LABEL
  } else if (percentile >= Config.PERCENTILE_MEETS_MID_MIN && percentile < Config.PERCENTILE_MEETS_HIGH_MIN) {
    category = Config.SCORE_MEETS_LABEL
  } else if (percentile >= Config.PERCENTILE_MEETS_HIGH_MIN && percentile < Config.PERCENTILE_EXCEEDS_MIN) {
    category = Config.SCORE_MEETS_HIGH_LABEL
  } else if (percentile >= Config.PERCENTILE_EXCEEDS_MIN && percentile <= Config.PERCENTILE_EXCEEDS_MAX) {
    category = Config.SCORE_EXCEEDS_LABEL
  }
  return category
}

export const getPercentileCategory = (percentile) => {
	let category = 0
  if (percentile >= Config.PERCENTILE_APPROACHING_MIN && percentile < Config.PERCENTILE_MEETS_LOW_MIN) {
    category = 1
  } else if (percentile >= Config.PERCENTILE_MEETS_LOW_MIN && percentile < Config.PERCENTILE_MEETS_MID_MIN) {
    category = 2
  } else if (percentile >= Config.PERCENTILE_MEETS_MID_MIN && percentile < Config.PERCENTILE_MEETS_HIGH_MIN) {
    category = 3
  } else if (percentile >= Config.PERCENTILE_MEETS_HIGH_MIN && percentile < Config.PERCENTILE_EXCEEDS_MIN) {
    category = 4
  } else if (percentile >= Config.PERCENTILE_EXCEEDS_MIN && percentile <= Config.PERCENTILE_EXCEEDS_MAX) {
    category = 5
  }
  return category
}

export const getPercentileColor = (percentile) => {
  let color = Config.SCORE_UNTESTED_COLOR;
  if (percentile >= Config.PERCENTILE_APPROACHING_MIN && percentile < Config.PERCENTILE_MEETS_LOW_MIN) {
    color = Config.SCORE_APPROACHING_COLOR;
  } else if (percentile >= Config.PERCENTILE_MEETS_LOW_MIN && percentile < Config.PERCENTILE_MEETS_MID_MIN) {
    color = Config.SCORE_MEETS_LOW_COLOR;
  } else if (percentile >= Config.PERCENTILE_MEETS_MID_MIN && percentile < Config.PERCENTILE_MEETS_HIGH_MIN) {
    color = Config.SCORE_MEETS_MID_COLOR
  } else if (percentile >= Config.PERCENTILE_MEETS_HIGH_MIN && percentile < Config.PERCENTILE_EXCEEDS_MIN) {
    color = Config.SCORE_MEETS_HIGH_COLOR;
  } else if (percentile >= Config.PERCENTILE_EXCEEDS_MIN && percentile <= Config.PERCENTILE_EXCEEDS_MAX) {
    color = Config.SCORE_EXCEEDS_COLOR;
  }
  return color;
}

const getScoreColor = (standardScore) => {
  let scoreColor = "secondary";
  if (standardScore >= Config.SCORE_APPROACHING_MIN && standardScore < Config.SCORE_MEETS_LOW_MIN) {
    scoreColor = "approaching";
  } else if (standardScore >= Config.SCORE_MEETS_LOW_MIN && standardScore < Config.SCORE_MEETS_MID_MIN) {
    scoreColor = "meets-low";
  } else if (standardScore >= Config.SCORE_MEETS_MID_MIN && standardScore < Config.SCORE_MEETS_HIGH_MIN) {
    scoreColor = "meets-mid";
  } else if (standardScore >= Config.SCORE_MEETS_HIGH_MIN && standardScore < Config.SCORE_EXCEEDS_MIN) {
    scoreColor = "meets-high";
  } else if (standardScore >= Config.SCORE_EXCEEDS_MIN && standardScore <= Config.SCORE_EXCEEDS_MAX) {
    scoreColor = "exceeds";
  }
  return scoreColor;
}

const getScoreColorHex = (standardScore) => {
  let scoreColor = Config.SCORE_UNTESTED_COLOR;
  if (standardScore >= Config.SCORE_APPROACHING_MIN && standardScore < Config.SCORE_MEETS_LOW_MIN) {
    scoreColor = Config.SCORE_APPROACHING_COLOR;
  } else if (standardScore >= Config.SCORE_MEETS_LOW_MIN && standardScore < Config.SCORE_MEETS_MID_MIN) {
    scoreColor = Config.SCORE_MEETS_LOW_COLOR;
  } else if (standardScore >= Config.SCORE_MEETS_MID_MIN && standardScore < Config.SCORE_MEETS_HIGH_MIN) {
    scoreColor = Config.SCORE_MEETS_MID_COLOR;
  } else if (standardScore >= Config.SCORE_MEETS_HIGH_MIN && standardScore < Config.SCORE_EXCEEDS_MIN) {
    scoreColor = Config.SCORE_MEETS_HIGH_COLOR;
  } else if (standardScore >= Config.SCORE_EXCEEDS_MIN && standardScore <= Config.SCORE_EXCEEDS_MAX) {
    scoreColor = Config.SCORE_EXCEEDS_COLOR;
  }
  return scoreColor;
}

const getScoreLabel = (standardScore) => {
  let label = Config.SCORE_UNTESTED_LABEL;
  if (standardScore >= Config.SCORE_APPROACHING_MIN && standardScore < Config.SCORE_MEETS_LOW_MIN) {
    label = Config.SCORE_APPROACHING_LABEL;
  } else if (standardScore >= Config.SCORE_MEETS_LOW_MIN && standardScore < Config.SCORE_MEETS_MID_MIN) {
    label = Config.SCORE_MEETS_LOW_LABEL;
  } else if (standardScore >= Config.SCORE_MEETS_MID_MIN && standardScore < Config.SCORE_MEETS_HIGH_MIN) {
    label = Config.SCORE_MEETS_LABEL;
  } else if (standardScore >= Config.SCORE_MEETS_HIGH_MIN && standardScore < Config.SCORE_EXCEEDS_MIN) {
    label = Config.SCORE_MEETS_HIGH_LABEL;
  } else if (standardScore >= Config.SCORE_EXCEEDS_MIN && standardScore <= Config.SCORE_EXCEEDS_MAX) {
    label = Config.SCORE_EXCEEDS_LABEL;
  }
  return label;
}

export const getPathPrefix = (orgPlan) => {
	let prefix = "/"
	// TODO Map to different prefix for different Plans if needed
	return prefix
}

const getOrgTypeLabel = (orgType, showPlural) => {
  let label = Config.ORG_TYPE_DEFAULT_LABEL;
  let type = Config.ORG_TYPE_OPTIONS.filter(item => item.value === orgType);
  if (type && type.length > 0 && type[0].label && type[0].label.length > 0) {
    label = type[0].label;
  }
  if (showPlural) {
    label += "s";
  }
  return label;
}

export const getOrgTypeIcon = (orgType) => {
  let icon = Config.ORG_TYPE_DEFAULT_ICON;
  let type = Config.ORG_TYPE_OPTIONS.filter(item => item.value === orgType);
  if (type && type.length > 0 && type[0].icon && type[0].icon.length > 0) {
    icon = type[0].icon;
  }
  return icon;
}

const getOrgSubGroupTypeLabel = (orgType, showPlural) => {
  let label = Config.GROUP_TYPE_DEFAULT_LABEL + (showPlural ? "s" : "");
  let type = Config.ORG_TYPE_OPTIONS.filter(item => item.value === orgType);
  if (type && type.length > 0 && type[0].subGroupType) {
    label = getGroupTypeLabel(type[0].subGroupType, showPlural);
  }
  return label;
}

export const getOrgSubGroupTypeIcon = (orgType) => {
  let icon = Config.GROUP_TYPE_DEFAULT_ICON
  let type = Config.ORG_TYPE_OPTIONS.filter(item => item.value === orgType);
  if (type && type.length > 0 && type[0].subGroupType) {
    icon = getGroupTypeIcon(type[0].subGroupType);
  }
  return icon;
}

const getOrgSubGroupType = (orgType) => {
  let subGroupType = Config.GROUP_TYPE_DEFAULT;
  let type = Config.ORG_TYPE_OPTIONS.filter(item => item.value === orgType);
  if (type && type.length > 0 && type[0].subGroupType) {
    subGroupType = type[0].subGroupType;
  }
  return subGroupType;
}

const getGroupTypeLabel = (groupType, showPlural) => {
  let label = Config.GROUP_TYPE_DEFAULT_LABEL + (showPlural ? "s" : "");
  let type = Config.GROUP_TYPE_OPTIONS.filter(item => item.value === groupType);
  if (type && type.length > 0 && type[0].label && type[0].label.length > 0) {
    label = type[0].label;
  }
  if (showPlural) {
    label += "s";
  }
  return label;
}

export const getGroupTypeIcon = (groupType) => {
  let icon = Config.GROUP_TYPE_DEFAULT_ICON;
  let type = Config.GROUP_TYPE_OPTIONS.filter(item => item.value === groupType);
  if (type && type.length > 0 && type[0].icon && type[0].icon.length > 0) {
    icon = type[0].icon;
  }
  return icon;
}

const getSubGroupType = (groupType) => {
  let subGroupType = Config.GROUP_TYPE_DEFAULT;
  let type = Config.GROUP_TYPE_OPTIONS.filter(item => item.value === groupType);
  if (type && type.length > 0 && type[0].subGroupType) {
    subGroupType = type[0].subGroupType;
  }
  return subGroupType;
}

const getSubGroupTypeLabel = (groupType, showPlural) => {
  let label = Config.GROUP_TYPE_DEFAULT_LABEL + (showPlural ? "s" : "");
  let type = Config.GROUP_TYPE_OPTIONS.filter(item => item.value === groupType);
  if (type && type.length > 0 && type[0].subGroupType) {
    label = getGroupTypeLabel(type[0].subGroupType, showPlural);
  }
  return label;
}

export const getSubGroupTypeIcon = (groupType) => {
  let icon = Config.GROUP_TYPE_DEFAULT_ICON;
  let type = Config.GROUP_TYPE_OPTIONS.filter(item => item.value === groupType);
  if (type && type.length > 0 && type[0].subGroupType) {
    icon = getGroupTypeIcon(type[0].subGroupType);
  }
  return icon;
}

export function filterGroupsForOrgs(groups, orgId, showUnassigned) {
  var filtered = [];
  if (orgId) {
    for(var j = 0; j < groups.length; j++) {
      if(groups[j].organizationId === orgId) {
        filtered.push(groups[j]);
      }
    }
    if (showUnassigned) {
      let unassigned = {};
      unassigned.name = "Unassigned"
      unassigned.id = Config.GROUP_ID_UNASSIGNED;
      unassigned.organizationId = orgId;
      filtered.push(unassigned);
    }  
  } else {
    filtered = groups;
  }
  return filtered;
}

export function filterGroupsForUser(groups, userAllowedGroups) {
  var filtered = [];
  if (userAllowedGroups) {
    for(var i = 0, len = userAllowedGroups.length; i < len; i++) {
      for(var j = 0; j < groups.length; j++) {
        if(userAllowedGroups[i].id === groups[j].id) {
          filtered.push(userAllowedGroups[i]);
          break;
        }
      }
    }
  } else {
    filtered = groups;
  }
  return filtered;
}

export function filterGroupsUnassigned(groups) {
  return groups.filter(function( group ) {
    return group.id !== Config.GROUP_ID_UNASSIGNED;
  });
}



//##############################__FLATTEN_GROUPS_ARRAY__#######################################

// let allgroups = [];
// const addGroupsToAllGroupsArray = (group) => {
//     allgroups.push(group);
//     group.children.forEach((subGroup)=>{
//         addGroupsToAllGroupsArray(subGroup);
//     });
// }

const flattenGroupsArray = (firstLevelGroupsArray) => {
    //this method will flatten the groups-array
    /*

    #
    |
    |____116
    |
    |____146
    |      |
    |      |____169
    |      |
    |      |____178
    |      |
    |      |____179
    |
    |____28
          |
          |____23
          |
          |____24

    ${result} THIS WILL BE FLATTENED TO an array : [ 116,146,169,178,179,28,23,24 ]
    */

    let allgroups = [];

    function addGroupsToAllGroupsArray(group) {
        allgroups.push(group);
        group.children.forEach((subGroup) => {
            addGroupsToAllGroupsArray(subGroup);
        });
    }

    firstLevelGroupsArray.forEach((group) => {
        addGroupsToAllGroupsArray(group);
    });

    return allgroups;
}

const getSearchResult = (allOrgs, searchString) => {
    let searchPositiveOrgs = [];

    allOrgs.map((org) => {
        if (checkIfStringContainsPattern(org.city, searchString)) { searchPositiveOrgs.push(org); }
        else if (checkIfStringContainsPattern(org.id, searchString)) { searchPositiveOrgs.push(org); }
        else if (checkIfStringContainsPattern(org.location, searchString)) { searchPositiveOrgs.push(org); }
        else if (checkIfStringContainsPattern(org.name, searchString)) { searchPositiveOrgs.push(org); }
    });

    return searchPositiveOrgs;

}

const checkIfStringContainsPattern = (string, pattern) => {
    string += "";
    pattern += "";
    string = string.toUpperCase();
    pattern = pattern.toUpperCase();

    return string.includes(pattern);
}


//############################# MISC. ARRAY METHODS #############################

const addElementToArrayUniquely = (element, array) => {
    //will not add element, if it already exists
    let elementAlreadyExist = false;
    array.map((data) => {
      if (data.id === element.id) {
        elementAlreadyExist = true;
      }
    });
    if (!elementAlreadyExist) {
      array.push(element);
    }
    return array;
  }

const deleteElementFromArray = (element, elementArray) => {
    let newElementArray = [];

    elementArray.filter((data) => {
      if (data.id != element.id) {
        newElementArray.push(data);
      }
    });
    return newElementArray;
  }

const checkIfElementExistsInArray = (element, elementArray) => {
    let exists = false;
    elementArray.map((data) => {
      if (data.id === element.id)
        exists = true;
    })
    return exists;
  }

//############################# COPY TO CLIPBOARD METHODS #############################

const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      return true;
  }

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }

//############################# GENERATE RANDOM STRING METHODS #############################


  const generateRandomString =(length)=> {
    //this method will generate random string of a particular length.
    let result             = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

// Convert Filters Object to a JSON array before passing to backend
const getFilterAsArray = (filters) => {
   var filtersArray = [];
   if (filters) {
     var keys = Object.keys(filters);
     for(var i=0; i<keys.length; i++){
       var key = keys[i];
       var origFilter = filters[key];
       var filter = {
         name: key,
         value: typeof origFilter.filterVal !== "object" ? origFilter.filterVal : null,  // single value
				 values: typeof origFilter.filterVal === "object" ? origFilter.filterVal : null, // array of values for multiselect filter
         type: origFilter.filterType,
				 comparator: origFilter.comparator,
         caseSensitive: origFilter.caseSensitive,
       };
       filtersArray.push(filter);
     }
   }
   return filtersArray;
 }

const getCopyrightMessage = () => {
   return "©2020-" + new Date().getFullYear() + ", Reflective Performance, Inc.";
}

function getDashboardURL(userRoleCode, plan, orgId) {
	localStorage.setItem("currentOrgId", orgId);
  let url = getOrgURL(plan, orgId);
  if (!orgId) {
    orgId = 0;
  }
	// if they only have access to groups, append it to the org URL
  if ((plan === PLAN_PERFORM_EF || plan === PLAN_REFLECT_EF) &&
      (userRoleCode === Config.ROLE_CODE_PARTICIPANT_L1 || userRoleCode === Config.ROLE_CODE_PARTICIPANT_L2 ||
       userRoleCode === Config.ROLE_CODE_PARTICIPANT_L3 || userRoleCode === Config.ROLE_CODE_GROUP_ADMIN)) {
      url += "/defaultGroup";
	}		 
  gotoPage(url);
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

function getOrgURL(plan, orgId) {
  let url = "/";
  if (!orgId) {
    orgId = 0;
  }
  url = "/organizations/" + orgId;
	return url;
}

export function getLogo(userProfile, orgPlan) {
  return logo;
}

export function getProductName(plan) {
	return "Reflect/EF"
}

function getCardColor(usedLicenses,purchasedLicenses,licenseExpDate) {
  var today = moment().format("YYYY-MM-DD");
  var expiryDate = moment(licenseExpDate,"YYYY-MM-DD"); 
 
  var cardColor = "bg-success";
  if((expiryDate.isAfter(today) && differenceInMonths(licenseExpDate, new Date()) < 1)
          || (parseInt(usedLicenses) > parseInt(purchasedLicenses) )) {
    cardColor = "bg-warning";
  } else if(isExpired(licenseExpDate)) {
    cardColor = "bg-danger";
  }
  return cardColor;
}

function getAge(dateOfBirth, includeMonths) {
  if (includeMonths) {
    return formatNoOfMonths(differenceInMonths(new Date(), dateOfBirth));
  } else {
    let temp_DOB = moment(dateOfBirth).format("MM/DD/YYYY");
    return Math.floor((new Date() - new Date(temp_DOB)) / 1000 / 60 / 60 / 24 / 365.25);
  }
}

function getYearFromMonths(ageInMonths) {
  return Math.floor(ageInMonths/12);
}

function getRemainingMonthFromMonths(ageInMonths) {
  return ageInMonths % 12
}

export function getDefaultTab(activeTab, userRole) {
	if (activeTab && activeTab !== "") {
		return activeTab;
	}
	if (userRole && userRole.priorityScore <= 444) {
		return Config.PARTICIPANTS;
	}
	return "dashboard";
}

export function shortenEmail(email, maxLocalLength = 3, maxDomainLength = 5) {
	if (!email) { return }
  // Split the email into the local part and domain part
  let [localPart, domainPart] = email.split('@');

  // Shorten the local part if necessary
  if (localPart.length > maxLocalLength) {
    localPart = `${localPart.substring(0, maxLocalLength)}...`;
  }

  // Shorten the domain part if necessary
  if (domainPart.length > maxDomainLength) {
    domainPart = `${domainPart.substring(0, maxDomainLength)}...`;
  }

  // Combine the shortened parts
  return `${localPart}@${domainPart}`;
}

function validateEmail(email) {
  if(validator.isEmail(email)) {
    return true;
  }
  return false;
}

export {
    Logger,
    randomNumberGenerator,
    reloadPage,
    goBack,
    gotoPage,
    getStatusBadge,
    goHome,
    flattenGroupsArray,
    getSearchResult,
    addElementToArrayUniquely,
    deleteElementFromArray,
    checkIfElementExistsInArray,
    copyTextToClipboard,
    generateRandomString,
    getFilterAsArray,
    getScoreCategory,
    getScoreColor,
    getScoreColorHex,
    getScoreLabel,
    getOrgTypeLabel,
    getOrgSubGroupType,
    getOrgSubGroupTypeLabel,
    getGroupTypeLabel,
    getSubGroupType,
    getSubGroupTypeLabel,
    getCopyrightMessage,
    getDashboardURL,
		getOrgURL,
    getCardColor,
    getAge,
    getYearFromMonths,
    getRemainingMonthFromMonths,
		formatPercentageFromDecimal,
    formatPercentileRank,
    formatStandardScore,
    validateEmail
}
