import React from 'react'
import { Routes, Route } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga4";
import Loading from './views/misc/Loading';
import './scss/style.scss'
import './translations/i18next';

// Containers
const AuthCallbackPage = React.lazy(() => import('./auth/AuthCallbackPage.js'))
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'))
const FullPageLayout = React.lazy(() => import('./containers/FullPageLayout'))
const PrintPageLayout = React.lazy(() => import('./containers/PrintPageLayout'))
const PublicLayout = React.lazy(() => import('./containers/PublicLayout'))

function App(props) {
	// Google Analytics
	ReactGA.initialize("G-V6NKN5RB19");

	const { isLoading } = useAuth0();

	if (isLoading) {
		return (
			<div className="page-layout">
				<Loading message="Loading..." />
			</div>
		);
	}

	return (
		<Routes>
			<Route element={<PublicLayout />}>
				<Route path="/account/sessiontimeout" />
				<Route path="/logout" />
				<Route path="/partner/*/auth" />
			</Route>
			<Route element={<AuthCallbackPage/>}>
				<Route path="/callback" />
			</Route>
			<Route element={<PrintPageLayout />}>
				<Route path="/report/*" />
			</Route>
			<Route element={<FullPageLayout />}>
				<Route path="/signup/*" />
				<Route path="/partner/*/launch" />
			</Route>
			<Route element={<AdminLayout />}>
				<Route path="*" />
			</Route>
		</Routes>
	)
}

export default App
