import { filesApiURL, filesUploadApiURL, getGroupScoreSummaryApiURL, getJourneyApiURL, getScoresApiURL, getSummaryApiURL, groupsApiURL, groupsInGroupAllApiURL, groupsInGroupApiURL, groupsInOrgAllApiURL, groupsInOrgApiURL, journeyApiURL, organizationsApiURL, organizationsApiURLChunk, particularGroupInParticularOrgApiURL, puppeteerApiURL, scoreSummaryTrendApiURL, singleGroupApiURL, singleOrganizationApiURL, staticFilesDownloadApiURL } from "../api_manager/List";
import { responseVerification } from "../api_manager/Methods";
import { API_METHODS } from "../api_manager/ResponseCodes";
import logoPerform from '../../assets/img/brand/logo-think-forward-300.png';
import logoReflect from '../../assets/img/brand/logo-think-forward-300.png';
import logoGeneric from '../../assets/img/brand/logo-think-forward-300.png';
import API_CALL from "../api_manager";

export const getOrganizationDetails = (orgId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleOrganizationApiURL(orgId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const modifyOrganization = (orgId, accessToken, methods, successCallback, errorCallback, payload = null, isVerificationRequired = true) => {
	let url = singleOrganizationApiURL(orgId)
	let method = (methods == "PUT") ? API_METHODS.PUT : API_METHODS.DELETE
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const createOrganization = (accessToken, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = organizationsApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getOrganizationChunkData = (startIndex, endIndex, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = organizationsApiURLChunk(startIndex, endIndex)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupDetails = (orgId, groupId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = singleGroupApiURL(orgId, groupId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getOrganizationSummary = (orgId, accessToken, successCallback, errorCallback, groupId = null, startDate = null, endDate = null, isVerificationRequired = true) => {
	let url = getSummaryApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getOrganizationTestSummaries = (orgId, accessToken, successCallback, errorCallback, groupId = null, startDate = null, endDate = null, isVerificationRequired = true) => {
	let url = scoreSummaryTrendApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getReportsFor = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = puppeteerApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getFilesForExport = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const putFilesForExport = (accessToken, payloadData, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesApiURL
	let method = API_METHODS.PUT
	API_CALL(accessToken, method, url, payloadData, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const downloadStaticFilesFor = (fileId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = staticFilesDownloadApiURL(fileId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getPresignedURLforExtension = (fileExt, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = filesUploadApiURL(fileExt)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupsInGroups = (orgId, groupId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsInGroupApiURL(orgId, groupId);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupsOfOrgs = (orgId, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsInOrgApiURL(orgId);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const addNewGroup = (accessToken, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = groupsApiURL
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getSingleGroupInOrg = (orgId, groupId, methods, accessToken, successCallback, errorCallback, payload = null, isVerificationRequired = true) => {
	let url = particularGroupInParticularOrgApiURL(orgId, groupId)
	let method = (methods == "PUT") ? API_METHODS.PUT : API_METHODS.DELETE
	API_CALL(accessToken, method, url, payload, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getAllGroupsData = (orgId, groupId = null, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	var getGroupsURL = null
	if (groupId != 0) {
		getGroupsURL = groupsInGroupAllApiURL(orgId, groupId);
	} else {
		getGroupsURL = groupsInOrgAllApiURL(orgId);
	}
	console.log(getGroupsURL)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, getGroupsURL, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode, false)) {
			if (errorCallback) errorCallback(successData.statusCode)
			return
		}
		if (successCallback) successCallback(successData);
	}, (errorData) => {
		if (errorCallback) errorCallback(errorData);
	});
}

export const getScores = (orgId, groupId, startDate, endDate, accessToken, successCallback, errorCallback, isVerificationRequired = true) => {
	var url = getScoresApiURL(orgId, groupId, startDate, endDate)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const getGroupScoreSummary = (orgId, groupId, accessToken, successCallback, errorCallback, startDate = null, endDate = null) => {
	var url = (startDate == null && endDate == null) ? getGroupScoreSummaryApiURL(orgId, groupId) : getGroupScoreSummaryApiURL(orgId, groupId, startDate, endDate);
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const CHILD_LIMIT_FREE_PLAN = 35;
export const USER_LIMIT_FREE_PLAN  = 2;
export const CHILD_LIMIT_PLUS_PLAN = 35;
export const USER_LIMIT_PLUS_PLAN  = 2;
export const CHILD_LIMIT_PRO_PLAN  = 100000;
export const USER_LIMIT_PRO_PLAN   = 1000;

export const PLAN_REFLECT_EF = "REFLECT_EF";
export const PLAN_PERFORM_EF = "PERFORM_EF";
export const PLAN_DEFAULT = PLAN_REFLECT_EF;

const planArray = [
	{
		name: "PERFORM/EF",
		code: PLAN_PERFORM_EF,
		color: 'primary',
		level: 40,
		logo: logoPerform
	},
	{
		name: "REFLECT/EF",
		code: PLAN_REFLECT_EF,
		color: 'dark',
		level: 30,
		logo: logoReflect
	}
];

export const getPlanDisplayColor = (code) => {
	let displayColor = "secondary"
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		displayColor = planOption.color
	}
	return displayColor
}

export const getPlanLogo = (code) => {
	let logo = logoGeneric
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		logo = planOption.logo
	}
	return logo
}

export const getPlanDisplayName = (code) => {
	let displayName = ""
	let planOption = planArray.find((plan, index) => {
		return plan.code === code
	})
	if (planOption) {
		displayName = planOption.name
	}
	return displayName
}

export const getPlanFilterOptions = () => {
	let filterOptions = {};
	planArray.map((plan, index) => {
		filterOptions[plan.code] = plan.name
	});
	return filterOptions;
}

export const getPlanSelectOptions = () => {
	let options = []
	planArray.map((plan, index) => {
		options.push(
			{
				value: plan.code,
				label: getPlanDisplayName(plan.code),
			}
		)
	});
	return options;
}

export const getSelectedPlan = (matchPlan) => {
	return getPlanSelectOptions().find((plan) => {
		return (plan.value === matchPlan) ? plan : null;
	});
};

/**
 * First use journey from Group, then Org, and default if neither is set
 * @param {*} org 
 * @param {*} group 
 * @returns 
 */
export const getDefaultJourneyTypeName = (org, group) => {
	if (group?.journeyTypeName?.length > 0) {
		return group?.journeyTypeName
	} else if (org?.journeyTypeName?.length > 0) {
		return org?.journeyTypeName
	} 
	return JOURNEY_DEFAULT_NAME
}

export const addNewJourney = (accessToken, orgId, groupId, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId);
	let method = API_METHODS.POST;
	API_CALL(accessToken, method, url, payload, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const editJourney = (accessToken, orgId, groupId, journeyId, data, successCallback, errorCallback, isVerificationRequired = true) => {
    let url = getJourneyApiURL(orgId, groupId, journeyId)
    let method = API_METHODS.PUT
    API_CALL(accessToken, method, url, data, (successData) => {
		successCallback(successData);
    }, (errorData) => {
        errorCallback(errorData)
    });
}

export const getJourneyDetail = (accessToken, orgId, groupId, journeyId, successCallback, errorCallback, isVerificationRequired = true) => {
    let url = getJourneyApiURL(orgId, groupId, journeyId)
    let method = API_METHODS.GET
    API_CALL(accessToken, method, url, null, (successData) => {
        if(isVerificationRequired && !responseVerification(successData.statusCode)) {
            errorCallback(successData.statusCode)
			return            
        }
        successCallback(successData)
    }, (errorData) => {
        errorCallback(errorData)
    });
}