export const TRANSLATIONS_ES = {
	language: "Language",

	assessment: {
		score: {
			percentile: {
				name: "Percentil Nacional",
				description: "ilustra la puntuación de un niño en comparación con niños de la misma edad. Varía entre 0 y 100, siendo el 50% la media."
			}
		}
	},
	reports: {
		education: {
			family: {
				name: "Informe de descripción general del niño",
				greeting: "Estimados miembros de la familia",
				overviewEf: "La función ejecutiva (FE) es un conjunto de habilidades basadas en el cerebro que ayuda a las personas a controlar su propio comportamiento. \
					y dirigirlo hacia objetivos en lugar de hacer lo automático o lo más fácil. Las habilidades FE son importantes para \
					poder aprender en el salón de clases, llevarse bien con otros niños y tomar buenas decisiones. El tres \
					Las habilidades de FE incluyen memoria de trabajo, flexibilidad cognitiva y control inhibitorio. Estas habilidades pueden ser \
					medido y reforzado a través de la reflexión y la práctica.",
				overviewGame: "Su hijo participó en una evaluación de sus habilidades de funciones ejecutivas llamada EFgo™ similar \
				  a un juego. Este juego les pide a los niños que emparejen y clasifiquen imágenes en función de características como \
					el tamaño, el color y la forma. En cada nivel, se le pidió a su hijo que siguiera cierta regla y luego la regla cambia, \
					y se le pidió que siguiera la regla nueva. Para jugar a este juego, los niños deben prestar atención, recordar las reglas \
					y pensar con flexibilidad.",
				overviewResults: "Los siguientes son los resultados de la evaluación más reciente y cómo los puntajes alineados con la expectativa de edad en comparación con nuestras normas nacionales de más de 53,000 niños.",
				activities: {
					overview: "Las siguientes son algunas ideas de actividades para apoyar el crecimiento de las habilidades de FE:",
					option1: "Practique juegos de movimiento que requieran que los niños calmen sus cuerpos, como caminar en línea recta perfecta, hacer posturas de yoga simples o 'congelarse' en medio de una acción.",
					option2: "Trate de cambiar las reglas a juegos con los que los niños se sientan cómodos jugando (por ejemplo, jugar frente a \
						Simón Dice donde NO hace las acciones que Simón Dice, o cantar la canción BINGO comenzando sin letras y las agrega, en \
						lugar de omitarlas).",
					option3: "Haga que su hijo comparta y reflexione sobre lo que hizo en la escuela durante el día. El proceso verbal y la reflexión son clave para el crecimiento de FE.",
				},
				learnMore: "Para obtener más información, consulte los recursos GRATUITOS de Reflective Performance para padres"
			}
		}
	},

	forms: {
		common: {
			selectMonth: "Select Month...",
			enter: "Enter",
			months: {
				january: "January",
				february: "February",
				march: "March",
				april: "April",
				may: "May",
				june: "June",
				july: "July",
				august: "August",
				september: "September",
				october: "October",
				november: "November",
				december: "December"
			},
		},
		participant: {
			studentId: "Participant ID",
			email: "Email",
			name: "Name",
			firstName: "First Name",
			lastName: "Last Name",
			selectGender: "Select Gender...",
			gender: "Gender",
			genderOptions: {
				female: "Female",
				male: "Male",
				other: "Gender diverse",
				private: "Prefer not to say"
			},
			birthMonthYear: "Birth Month / Year",
			birthYear: "Birth Year",
			selectBirthMonth: "Select Birth Month...",
			add: {
				title: "Need to be added?",
				message: "Add individual to be assessed",
				button: "Continue"
			},
			edit: {
				title: "Update information of individual to be assessed",
				button: "Continue"
			},
		},
	}
}