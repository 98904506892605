//0 for local , 1 for dev-admin , 2 for staging-admin , 3 for prod-admin
export const envIndex = process.env.REACT_APP_HOST_ENV;

export const BASE_URL = [
  "http://localhost:3000",
  "https://dev-admin.reflectiveperformanceinc.com",
  "https://staging-admin.reflectiveperformanceinc.com",
  "https://admin.reflectiveperformanceinc.com"
];
  
export  const BASE_ASSESSMENT_URL = [
  "https://dev-measure.reflectiveperformanceinc.com",
  "https://dev-measure.reflectiveperformanceinc.com",
  "https://staging-measure.reflectiveperformanceinc.com",
  "https://measure.reflectiveperformanceinc.com"
];
  
export const ENV = {
  baseUrl: BASE_URL[envIndex],
  baseAssessmentUrl: BASE_ASSESSMENT_URL[envIndex],
}

export const ROLE_CODE_INTERNAL_SUPER_ADMIN   = "ROLE_SADMIN";
export const ROLE_CODE_INTERNAL_SUPPORT_ADMIN = "ROLE_IADMIN_SUPPORT";
export const ROLE_CODE_SYSTEM_ADMIN           = "ROLE_LADMIN";
export const ROLE_CODE_GROUP_ADMIN            = "ROLE_GADMIN";
export const ROLE_CODE_PARTICIPANT_L3         = "ROLE_PARTICIPANT_L3";
export const ROLE_CODE_PARTICIPANT_L2         = "ROLE_PARTICIPANT_L2";
export const ROLE_CODE_PARTICIPANT_L1         = "ROLE_PARTICIPANT_L1";

export const ORG_TYPE_DEFAULT             = "";
export const ORG_TYPE_DEFAULT_LABEL       = "Organization";
export const ORG_TYPE_DEFAULT_ICON        = "fa fa-building";
export const ORG_TYPE_NONPROFIT           = "NON-PROFIT";
export const ORG_TYPE_NONPROFIT_LABEL     = "Non-Profit";
export const ORG_TYPE_NONPROFIT_ICON      = "fa fa-building";
export const ORG_TYPE_CLINIC              = "CLINIC";
export const ORG_TYPE_CLINIC_LABEL        = "Clinic";
export const ORG_TYPE_CLINIC_ICON         = "fa fa-building";
export const ORG_TYPE_RESEARCH            = "RESEARCH";
export const ORG_TYPE_RESEARCH_LABEL      = "Research";
export const ORG_TYPE_RESEARCH_ICON       = "fa fa-building";
export const ORG_TYPE_OTHER               = "OTHER";
export const ORG_TYPE_OTHER_LABEL         = "Other";
export const ORG_TYPE_OTHER_ICON          = "fa fa-building";

export const GROUP_TYPE_DEFAULT        = "";
export const GROUP_TYPE_DEFAULT_LABEL  = "Group";
export const GROUP_TYPE_DEFAULT_ICON   = "fa fa-folder-tree";
export const GROUP_TYPE_REGION         = "REGION";
export const GROUP_TYPE_REGION_LABEL   = "Region";
export const GROUP_TYPE_REGION_ICON    = "fa fa-map";
export const GROUP_TYPE_OTHER          = "OTHER";
export const GROUP_TYPE_OTHER_LABEL    = "Group";
export const GROUP_TYPE_OTHER_ICON     = "fa fa-folder-tree";
export const GROUP_ID_UNASSIGNED       = -1;

export const USERS = "users";
export const USER_DATA_MANAGEMENT = "userDataManagement";
export const GROUPS = "groups";
export const GROUP_DATA_MANAGEMENT = "groupsDataManagement";
export const PARTICIPANTS = "participant";
export const PARTICIPANT_DATA_MANAGEMENT = "childDataManagement";
export const GENERAL = "general";

export const GOOGLE_PLACE_API_KEY = 'AIzaSyDSkM0eZI5EOsHOL68qTA-aN2yQeZMA92M';

export const ORG_TYPE_OPTIONS = [
    { "value" : ORG_TYPE_NONPROFIT, "label": ORG_TYPE_NONPROFIT_LABEL, "subGroupType" : GROUP_TYPE_OTHER, "icon" : ORG_TYPE_NONPROFIT_ICON },
    { "value" : ORG_TYPE_CLINIC,    "label": ORG_TYPE_CLINIC_LABEL,    "subGroupType" : GROUP_TYPE_OTHER, "icon" : ORG_TYPE_CLINIC_ICON },
    { "value" : ORG_TYPE_RESEARCH,  "label": ORG_TYPE_RESEARCH_LABEL,  "subGroupType" : GROUP_TYPE_OTHER, "icon" : ORG_TYPE_RESEARCH_ICON },
    { "value" : ORG_TYPE_OTHER,     "label": ORG_TYPE_OTHER_LABEL,     "subGroupType" : GROUP_TYPE_OTHER, "icon" : ORG_TYPE_OTHER_ICON },
];

export const GROUP_TYPE_OPTIONS = [
		{ "value" : GROUP_TYPE_REGION,   "label": GROUP_TYPE_REGION_LABEL,  "subGroupType" : GROUP_TYPE_DEFAULT,  "icon" : GROUP_TYPE_REGION_ICON },
    { "value" : GROUP_TYPE_OTHER,    "label": GROUP_TYPE_OTHER_LABEL,   "subGroupType" : GROUP_TYPE_OTHER,    "icon" : GROUP_TYPE_OTHER_ICON },
];

export const STATUS_ALL   	 = "";
export const STATUS_ACTIVE   = "ACTIVE";
export const STATUS_INACTIVE = "INACTIVE";

export const STATUS_OPTIONS = {
	"ACTIVE": STATUS_ACTIVE,
	"INACTIVE": STATUS_INACTIVE,
};
export const STATUS_FILTER_ALL = [
	STATUS_ACTIVE,
	STATUS_INACTIVE,
];

export const SOURCE_INTERNAL_ADMIN  = "INTERNAL_ADMIN";
export const SOURCE_CUSTOMER_SIGNUP = "CUSTOMER_SIGNUP";
export const SOURCE_CUSTOMER_ADMIN  = "CUSTOMER_ADMIN";

export const RELEASE_NOTES_VIEWED  = "AdminViewed";

export const RELEASE_NOTES_VERSION  = "1.2.0";

export const SCORE_APPROACHING_LABEL = 'Approaching';
export const SCORE_APPROACHING_COLOR = '#caf0f8';
export const SCORE_MEETS_LABEL       = 'Meets';
export const SCORE_MEETS_LOW_LABEL   = 'Meets-Low';
export const SCORE_MEETS_LOW_COLOR   = '#90e0ef';
export const SCORE_MEETS_MID_LABEL   = 'Meets-Mid';
export const SCORE_MEETS_COLOR       = '#00b4d8';
export const SCORE_MEETS_MID_COLOR   = '#00b4d8';
export const SCORE_MEETS_HIGH_LABEL  = 'Meets-High';
export const SCORE_MEETS_HIGH_COLOR    = '#0077b6';
export const SCORE_EXCEEDS_LABEL       = 'Exceeds';
export const SCORE_EXCEEDS_COLOR       = '#03045e';

export const SCORE_UNTESTED_LABEL    = 'Unassessed';
export const SCORE_UNTESTED_COLOR    = '#cccccc';
export const SCORE_LABELS            = [SCORE_APPROACHING_LABEL, SCORE_MEETS_LOW_LABEL, SCORE_MEETS_MID_LABEL, SCORE_MEETS_HIGH_LABEL, SCORE_EXCEEDS_LABEL];
export const SCORE_COLORS            = [SCORE_APPROACHING_COLOR, SCORE_MEETS_LOW_COLOR, SCORE_MEETS_MID_COLOR, SCORE_MEETS_HIGH_COLOR, SCORE_EXCEEDS_COLOR];
export const SCORE_COLORS_UNTESTED   = [SCORE_UNTESTED_COLOR, SCORE_UNTESTED_COLOR, SCORE_UNTESTED_COLOR, SCORE_UNTESTED_COLOR, SCORE_UNTESTED_COLOR];
export const SCORE_CATEGORY_FILTER_UNTESTED    = 0;
export const SCORE_CATEGORY_FILTER_APPROACHING = 1;
export const SCORE_CATEGORY_FILTER_MEETS_LOW   = 2;
export const SCORE_CATEGORY_FILTER_MEETS_MID   = 3;
export const SCORE_CATEGORY_FILTER_MEETS_HIGH  = 4;
export const SCORE_CATEGORY_FILTER_EXCEEDS     = 5;
export const SCORE_CATEGORY_FILTER_ALL = [
    SCORE_CATEGORY_FILTER_UNTESTED,
    SCORE_CATEGORY_FILTER_APPROACHING,
    SCORE_CATEGORY_FILTER_MEETS_LOW,
    SCORE_CATEGORY_FILTER_MEETS_MID,
    SCORE_CATEGORY_FILTER_MEETS_HIGH,
    SCORE_CATEGORY_FILTER_EXCEEDS];
export const SCORE_CATEGORY_FILTER_ASSESSED = [
		SCORE_CATEGORY_FILTER_APPROACHING,
		SCORE_CATEGORY_FILTER_MEETS_LOW,
		SCORE_CATEGORY_FILTER_MEETS_MID,
		SCORE_CATEGORY_FILTER_MEETS_HIGH,
		SCORE_CATEGORY_FILTER_EXCEEDS];
export const SCORE_MIN               = 60;
export const SCORE_MAX               = 140;
export const SCORE_APPROACHING_MIN   = 60;
export const SCORE_APPROACHING_MAX   = 84;
export const SCORE_MEETS_LOW_MIN     = 85;
export const SCORE_MEETS_LOW_MAX     = 94;
export const SCORE_MEETS_MID_MIN     = 95;
export const SCORE_MEETS_MID_MAX     = 104;
export const SCORE_MEETS_HIGH_MIN    = 105;
export const SCORE_MEETS_HIGH_MAX    = 115;
export const SCORE_EXCEEDS_MIN       = 116;
export const SCORE_EXCEEDS_MAX       = 140;

export const PERCENTILE_MIN               = 0;
export const PERCENTILE_MAX               = 100;
export const PERCENTILE_APPROACHING_MIN   = 0;
export const PERCENTILE_APPROACHING_MAX   = 16;
export const PERCENTILE_MEETS_LOW_MIN     = 17;
export const PERCENTILE_MEETS_LOW_MAX     = 38;
export const PERCENTILE_MEETS_MID_MIN     = 39;
export const PERCENTILE_MEETS_MID_MAX     = 60;
export const PERCENTILE_MEETS_HIGH_MIN    = 61;
export const PERCENTILE_MEETS_HIGH_MAX    = 83;
export const PERCENTILE_EXCEEDS_MIN       = 84;
export const PERCENTILE_EXCEEDS_MAX       = 100;

export const SCORE_CATEGORY_OPTIONS = {
    0: SCORE_UNTESTED_LABEL,
    1: SCORE_APPROACHING_LABEL,
    2: SCORE_MEETS_LOW_LABEL,
    3: SCORE_MEETS_MID_LABEL,
    4: SCORE_MEETS_HIGH_LABEL,
    5: SCORE_EXCEEDS_LABEL,
};

export const FEATURE_CODE_ALLOW_PART_EMAIL = "ALLOW_PARTICIPANT_EMAIL";

export const SESSION_TYPE_ASSESSMENT          = "ASSESSMENT";

export const SESSION_INITIATE_METHOD_LAUNCH = 'launch';// 'launch' on this device
export const SESSION_INITIATE_METHOD_SEND   = 'send';// 'send' to another device

export const SESSION_TAGS = {
	AdultAssess: 'ADULT_ASSESS',
	AllowSearchByName: 'ALLOW_SEARCH_BY_NAME',
	AllowAdd: 'ALLOW_ADD',
	AllowAdvOptions: 'ALLOW_ADV_OPTIONS',
	AllowNotes: 'ALLOW_NOTES',
	DefaultExaminerLed: 'DEFAULT_EXAMINER_LED',
	EnableBetaVersion: 'ENABLE_BETA_VERSION'
}

export const sessionTagOptions = [
	{ value: SESSION_TAGS.AdultAssess, label: 'Adult Assessment' },
	{ value: SESSION_TAGS.AllowAdd, label: 'Allow Add' },
	{ value: SESSION_TAGS.AllowSearchByName, label: 'Allow Search By Name' },
	{ value: SESSION_TAGS.AllowAdvOptions, label: 'Allow Adv Options' },
	{ value: SESSION_TAGS.AllowNotes, label: 'Allow Notes' },
	{ value: SESSION_TAGS.DefaultExaminerLed, label: 'Default To Examiner Led' },
	{ value: SESSION_TAGS.EnableBetaVersion, label: 'Enable Beta Version' },
]

export const getHelpTopicName = (tabName = null) => {
  switch(tabName) {
    case USERS:
        return USER_DATA_MANAGEMENT;
    case GROUPS:
        return GROUP_DATA_MANAGEMENT;
    case PARTICIPANTS:
      return PARTICIPANT_DATA_MANAGEMENT;
    default: 
      return GENERAL;
  }
}
