import * as actions from "../actionTypes/orgActionTypes";

let initialState = {
  org: null,
  orgDisplayName: "",
  isOrgLoaded: false,
  entityPath: [],
  groupEntityPath: [],
  summaryData: null,
  isOrgSummaryLoaded: false,
  testSummaries: null,
  isTestSummariesLoaded: false,
  isGroupLoaded: false,
  group: null,
  groupAlias: "Group",
  subGroupAlias: "Group",
  subGroupType: null,
  isGroupSummaryLoaded: false,
  showInactive: false
};

export default function orgReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ORGANIZATION:
      return {
        ...state,
        isOrgLoaded: action.payload.loaded,
        org: action.payload.organization,
        entityPath: action.payload.entityPath ? action.payload.entityPath : state.entityPath,
        orgDisplayName: action.payload.organizationDisplayName
      };
    case actions.SET_ORGANIZATION_SUMMARY:
      return {
        ...state,
        summaryData: action.payload.summary,
        isOrgSummaryLoaded: action.payload.loaded,
        isGroupSummaryLoaded: false
      };
    case actions.SET_ORGANIZATION_TEST_SUMMARY:
      return {
        ...state,
        testSummaries: action.payload.summary,
        isTestSummariesLoaded: action.payload.loaded
      };
    case actions.SET_GROUP:
      return {
        ...state,
        isGroupLoaded: action.payload.loaded,
        group: action.payload.groupData,
        groupEntityPath: action.payload.entityPath ? action.payload.entityPath : state.entityPath,
        subGroupType: action.payload.subGroupType,
        groupAlias: action.payload.groupAlias,
        subGroupAlias: action.payload.subGroupAlias,
      };
    case actions.SET_GROUP_SUMMARY:
      return {
        ...state,
        summaryData: action.payload.summary,
        isOrgSummaryLoaded: false,
        isGroupSummaryLoaded: action.payload.loaded
      };
    case actions.SET_SHOW_INACTIVE:
      return {
        ...state,
        showInactive: action.payload
      };
		case actions.RESET_STATE:
			return initialState;
    default:
      return state;
  }
}